import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from "./api/client"

interface UserConfigParamProps {
  id: string | number
  tipo: string
}

export interface StatesListProps {
  estado?: string,
  uf?: string,
  label?: string,
}

export interface LogisticProps {
  valor_chip?: string,
  valor_frete?: string
  valor_chip_avulso?: string,
  valor_frete_avulso?: string
}

export interface ChipInfo {
  chipPrice?: string
  shippingPrice?: string
  chipPriceOTP?: string
  shippingPriceOTP?: string
}

export interface UserConfigsProps {
  nome: string
  plano: string
  valor: string
  periodo: string
  esim: boolean
  esim_pdf: string
  portabilidade: boolean
}

export interface Requests {
  statesList: StatesListProps[]
  chipInfo: ChipInfo
  loadingChipReq: boolean
  currentUserConfigs: UserConfigsProps | null
  loadingUserConfigs: boolean
  userConfigParam: UserConfigParamProps
}

const defaultRequestsData: Requests = {
  statesList: [],
  chipInfo: {},
  loadingChipReq: false,
  currentUserConfigs: null,
  loadingUserConfigs: false,
  userConfigParam: {id: 0, tipo: ''}
}

const RequestsContext = createContext<Requests>(defaultRequestsData)

export function useInitReq() {
  return useContext(RequestsContext)
}

export function InitReqProvider({ children }: PropsWithChildren) {
  const [currentPath, setCurrentPath] = useState<string>()
  const [statesList, setStatesList] = useState<StatesListProps[]>([])
  const [chipInfo, setChipInfo] = useState<ChipInfo>({})
  const loadingChipReq = useRef(false)
  const [currentUserConfigs, setCurrentUserConfigs] = useState<UserConfigsProps | null>(null)
  const [loadingUserConfigs, setLoadingUserConfigs] = useState<boolean>(false)
  const [userConfigParam, setUserConfigParam] = useState<UserConfigParamProps>({id: 0, tipo: ''})
  const location = useLocation()
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const id = queryParams.get('id')
    const tipo = queryParams.get('tipo')
    const param = location.pathname.split('/')[1]
    
    if (id && tipo) setUserConfigParam({id, tipo})
    setCurrentPath(param)
  }, [location, currentPath])

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await requests.statesList()
        setStatesList(response)
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchChip() {
      try {
        const { valor_chip, valor_frete, valor_chip_avulso, valor_frete_avulso } = await requests.logistic()
        setChipInfo({
          chipPrice: valor_chip,
          shippingPrice: valor_frete,
          chipPriceOTP: valor_chip_avulso,
          shippingPriceOTP: valor_frete_avulso,
        })
        loadingChipReq.current = true
      } catch (error) {
        console.error('Erro ao inicializar requisições:', error)
      }
    }

    async function fetchUserConfigs() {
      setLoadingUserConfigs(true)
      
      if (userConfigParam) {
        try {
          const userConfigs = await requests.userConfigs(userConfigParam)
          if (userConfigs) {
            setCurrentUserConfigs(userConfigs)
          } else {
            console.error("Resposta inesperada da API:", userConfigs)
          }
        } catch (error) {
          console.error("Erro ao buscar configurações de usuário:", error)
        } finally {
          setLoadingUserConfigs(false)
        }
      }
    }

    if (currentPath === "configure-sua-conta") fetchUserConfigs()
    if (currentPath === '' || currentPath === 'vip') {
      fetchStates()
      fetchChip()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return (
    <RequestsContext.Provider
      value={{
        statesList,
        chipInfo,
        loadingChipReq: loadingChipReq.current,
        currentUserConfigs,
        loadingUserConfigs,
        userConfigParam
      }}
    >
      {children}
    </RequestsContext.Provider>
  )
}