import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Box, Grid, Hidden } from "@mui/material"
import backImage from '../../../assets/images/backImage.svg'
import backImageMob from '../../../assets/images/backImageMob.svg'
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'

interface ImageListProps {
  url: string
  render: boolean
}

export default function BackImage({ name, variables }: ISettingsItem) {
  const [deskImageList, setDeskImageList] = useState<ImageListProps[]>([{url: '', render: false}])
  const [mobImageList, setMobImageList] = useState<ImageListProps[]>([{url: '', render: false}])
  const location = useLocation()
  const { planIndex } = usePlan()
  const { image = [backImage, backImageMob] } = variables || {}

  useEffect(() => {
    const deskUrls = image.length > 2 ? image.slice(0, 5) : image.slice(0, 1)
    const mobUrls = image.length > 2 ? image.slice(5) : image.slice(1)
    
    if (image.length > 2) {
      setDeskImageList(deskUrls.map((url, index) => ({ url, render: index === planIndex ? true : false })))
      setMobImageList(mobUrls.map((url, index) => ({ url, render: index === planIndex ? true : false })))
    } else {
      setDeskImageList(deskUrls.map((url) => ({ url, render: true })))
      setMobImageList(mobUrls.map((url) => ({ url, render: true })))
    }
    // eslint-disable-next-line
  },[planIndex, location.pathname])

  return (
    <Grid item className={name ? `${name} back-image` : "back-image"}>
      <Hidden mdUp>
        {mobImageList.map(({url, render}, index) => (
          <Box
            key={index}
            component="img"
            className="backImageMobile"
            alt="Imagem de fundo da página principal"
            src={url}
            sx={{ display: render ? 'block' : 'none' }}
          />
        ))}
      </Hidden>
      <Hidden mdDown>
        {deskImageList.map(({url, render}, index) => (
          <Box
            key={index}
            component="img"
            className="backImageMobile"
            alt="Imagem de fundo da página principal"
            src={url}
            sx={{ display: render ? 'block' : 'none' }}
          />
        ))}
      </Hidden>
    </Grid>
  )
}