import { Grid, Skeleton } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import SwitchDefault from "../../SwitchDefault"
import { ReactComponent as RightArrow } from '../../../assets/icons/rightArrow.svg'

export default function SelectPlanDetails({ name, variables }: ISettingsItem) {
  const {
    monthlyPrice,
    annualPrice,
    setChosenPlan,
    portability,
    setPortability,
    loadingPlan
  } = usePlan()
  const { loadingChipReq } = useInitReq()
  const { text } = variables || {}

  const currentMonthlyPrice = monthlyPrice.toFixed(2).replace('.', ',')
  const currentAnnualPrice = annualPrice.toFixed(2).replace('.', ',')

  const defaultTextArray = [
    'Portabilidade',
    'Use seu número atual e ganhe bônus',
    '+5GB GRÁTIS',
    'MAIS PROCURADO',
    'Renove todo mês sem fidelidade',
    'MAIS RENTÁVEL',
    'Compre 12 e pague por 8 meses',
    'flex',
    'flex',
    'none',
    'none'
  ]

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const splitChoosePortabilityDetail = mergedArray[2].split(' ')

  const navigate = useNavigate()
  const handleCardClick = (chosen: string) => {
    navigate('/configure-seu-plano')
    setChosenPlan(chosen)
  }

  const handleSwitchChange = () => {
    setPortability(!portability)
  }

  return (
    <Grid container item className={name ? `${name} select-plan-details` : "select-plan-details"} xs={12}>
      <Grid container item className="choosePortability" xs={12} style={{ display: mergedArray[9] }}>
        <Grid item className="choosePortabilitySwitch" style={{ display: mergedArray[7] }}>
          <SwitchDefault checked={portability} onChange={handleSwitchChange} />
        </Grid>
        <Grid item className="choosePortabilityText">
          <h1>{mergedArray[0]}</h1>
          <p>{mergedArray[1]}</p>
        </Grid>
        <Grid item className="choosePortabilityDetail">
          <p>{splitChoosePortabilityDetail[0]} <span>{splitChoosePortabilityDetail[1]}</span></p>
        </Grid>
      </Grid>
      {
        loadingPlan && loadingChipReq ? (
          <Grid container item className="monthlyAnnualCards" style={{ display: mergedArray[8] }}>
            <Grid container item className="monthlyAnnualCard monthly" onClick={() => handleCardClick('monthly')}>
              <Grid container item className="monthlyAnnualTextArea" xs={8}>
                <Grid container item className="monthlyAnnualTitle">
                  <h1>Mensal</h1>
                  <Grid item className="monthlyAnnualDetails monthlyDetails">{mergedArray[3]}</Grid>
                </Grid>
                <p>{mergedArray[4]}</p>
              </Grid>
              <Grid item className="monthlyAnnualPriceArea" xs={4}>
                <Grid item className="monthlyPriceArea">
                  <h1>R$ {currentMonthlyPrice}</h1>
                </Grid>
                <RightArrow />
              </Grid>
            </Grid>
            <Grid container item className="monthlyAnnualCard annual" onClick={() => handleCardClick('annual')} style={{ display: mergedArray[10] }}>
              <Grid container item className="monthlyAnnualTextArea" xs={8}>
                <Grid container item className="monthlyAnnualTitle">
                  <h1>Anual</h1>
                  <Grid item className="monthlyAnnualDetails annualDetails">{mergedArray[5]}</Grid>
                </Grid>
                <p>{mergedArray[6]}</p>
              </Grid>
              <Grid container item className="monthlyAnnualPriceArea" xs={4}>
                <Grid item className="annualPriceArea">
                  <h1 className="oldPrice">R$ {currentMonthlyPrice}</h1>
                  <h1>R$ {currentAnnualPrice}</h1>
                </Grid>
                <RightArrow />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container item className="monthlyAnnualCards" style={{ display: mergedArray[8] }}>
            <Skeleton className="skltn-monthlyAnnualCard" variant="rounded" width="100%" />
            <Skeleton className="skltn-monthlyAnnualCard" variant="rounded" width="100%" style={{ display: mergedArray[10] }} />
          </Grid>
        )
      }
    </Grid>
  )
}