import { Stepper, Step, StepLabel, StepIconProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ReactComponent as CheckIcon } from '../assets/icons/checkIcon.svg'

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: '',
    display: 'flex',
    alignItems: 'center',
    ...(ownerState.active && {
      color: '',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 25,
      height: 25,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepLabel(props: StepIconProps & { stepNumber: number }) {
  const { active, completed, className, stepNumber } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-circle">
          <CheckIcon className="QontoStepIcon-completedIcon" />
        </div>
      ) : (
        <div className="QontoStepIcon-circle">
          <label htmlFor="QontoStepIcon-circle">{stepNumber}</label>
        </div>
      )}
    </QontoStepIconRoot>
  )
}

interface StepperProps {
  step: number
  steps: string[]
}

export default function StepperDefault({ step, steps }: StepperProps) {
  return (
    <Stepper activeStep={step} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel
            StepIconComponent={(props: StepIconProps) => <QontoStepLabel {...props} stepNumber={index + 1} />}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}