import React from 'react'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'

const StyledSwitch = styled((props: SwitchProps & { checked?: boolean }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 27,
  padding: 2,
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    marginTop: 3,
    marginLeft: 4,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'transparent',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border-color'], {
      duration: 300,
    }),
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
}))

interface SwitchDefaultProps {
  checked?: boolean,
  onChange?: () => void
}

export default function SwitchDefault({ checked, onChange, ...props }: SwitchDefaultProps) {
  return (
    <StyledSwitch checked={checked} onChange={onChange} {...props} />
  )
}
