import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from './api/client'

export interface Plan {
  id: number,
  plano: string,
  valor: number,
  nome_plano: string,
  ligacoes: string,
  sms: string,
  detalhamento: string,
  descricao_detalhamento: string,
  cor: string,
  internet_padrao: string
}

export interface PlansContext {
  plans: Plan[]
  currentPlan: Plan
  planClass: string
  monthlyPrice: number
  annualPrice: number
  chosenPlan: string
  virtualChip: boolean
  portability: boolean
  smartControl: boolean
  planConfigs: boolean
  planBonus: boolean
  planIndex: number
  step: number
  steps: string[]
  loadingPlan: boolean,
  currentPurchaseLink: string
}

interface updateContext extends PlansContext {
  updatedState: (plan: Plan) => void
  setChosenPlan: (chosenPlan: string) => void
  setvirtualChip: (virtualChip: boolean) => void
  setPortability: (portability: boolean) => void
  setSmartControl: (smartControl: boolean) => void
  setPlanIndex: (planIndex: number) => void
  setStep: (step: number) => void
  setSteps: (steps: string[]) => void
  setLoadingPlan: (steps: boolean) => void
  setCurrentPurchaseLink: (link: string) => void
}

const defaultPlan = {
  "id": 0,
  "plano": "",
  "valor": 0,
  "nome_plano": "",
  "ligacoes": "",
  "sms": "",
  "detalhamento": "",
  "descricao_detalhamento": "",
  "cor": "",
  "internet_padrao": ""
}

const defaultPlansData: PlansContext = {
  plans: [],
  currentPlan: defaultPlan,
  planClass: 'plan1',
  monthlyPrice: 39.9,
  annualPrice: 26.6,
  chosenPlan: '',
  virtualChip: false,
  portability: false,
  smartControl: false,
  planConfigs: false,
  planBonus: false,
  planIndex: 0,
  step: 0,
  steps: ['Seu Plano', 'Informações', 'Endereço', 'Pagamento'],
  loadingPlan: false,
  currentPurchaseLink: '',
}

const defaultPlansContext: updateContext = {
  ...defaultPlansData,
  updatedState: (plan: Plan) => { },
  setChosenPlan: (chosenPlan: string) => { },
  setvirtualChip: (virtualChip: boolean) => { },
  setPortability: (portability: boolean) => { },
  setSmartControl: (smartControl: boolean) => { },
  setPlanIndex: (planIndex: number) => { },
  setStep: (step: number) => { },
  setSteps: (steps: string[]) => { },
  setLoadingPlan: (loadingPlan: boolean) => { },
  setCurrentPurchaseLink: (link: string) => { },
}

const PlanContext = createContext<updateContext>(defaultPlansContext)
const colorPaletteClasses = ['plan1', 'plan2', 'plan3', 'plan4', 'plan5']

export function usePlan() {
  return useContext(PlanContext)
}

export function PlanProvider({ children }: PropsWithChildren) {
  const [currentPath, setCurrentPath] = useState<string>()
  const [currentState, setCurrentState] = useState<PlansContext>(defaultPlansData)
  const location = useLocation()
  
  useEffect(() => {
    const param = location.pathname.split('/')[1]
    setCurrentPath(param)
  }, [location, currentPath])

  const updatedState = useCallback((plan: Plan) => {
    const planIndex = currentState.plans.indexOf(plan) !== -1 ? currentState.plans.indexOf(plan) : 0
    const colorPaletteClass = colorPaletteClasses[planIndex]
    const currentMonthlyPrice = plan.valor
    const currentAnnualPrice = ((plan.valor * 8) / 12)

    setCurrentState((preContext) => ({
      ...preContext,
      currentPlan: plan,
      planClass: colorPaletteClass,
      monthlyPrice: currentMonthlyPrice,
      annualPrice: currentAnnualPrice,
      loadingPlan: true,
    }))
  }, [currentState])

  const setChosenPlan = useCallback((chosenPlan: string) => {
    setCurrentState((prevState) => ({
      ...prevState,
      chosenPlan: chosenPlan
    }))
  }, [])

  const setvirtualChip = useCallback((virtualChip: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      virtualChip: virtualChip,
      planConfigs: virtualChip || prevState.portability || prevState.smartControl
    }))
  }, [])

  const setPortability = useCallback((portability: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      portability: portability,
      planBonus: portability || prevState.smartControl,
      planConfigs: prevState.virtualChip || portability || prevState.smartControl
    }))
  }, [])

  const setSmartControl = useCallback((smartControl: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      smartControl: smartControl,
      planBonus: prevState.portability || smartControl,
      planConfigs: prevState.virtualChip || prevState.portability || smartControl
    }))
  }, [])

  const setPlanIndex = useCallback((planIndex: number) => {
    setCurrentState((prevState) => ({
      ...prevState,
      planIndex: planIndex
    }))
  }, [])

  const setStep = useCallback((step: number) => {
    setCurrentState((prevState) => ({
      ...prevState,
      step: step
    }))
  }, [])

  const setSteps = useCallback((steps: string[]) => {
    setCurrentState((prevState) => ({
      ...prevState,
      steps: steps
    }))
  }, [])

  const setLoadingPlan = useCallback((loadingPlan: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      loadingPlan: loadingPlan
    }))
  }, [])

  const setCurrentPurchaseLink = useCallback((link: string) => {
    setCurrentState((prevState) => ({
      ...prevState,
      currentPurchaseLink: link
    }))
  }, [])

  useEffect(() => {
    async function fetchPlans() {
      try {
        const plansArray = await requests.plansList()

        if (Array.isArray(plansArray)) {
          const updatedPlan: PlansContext = {
            ...currentState,
            plans: plansArray as Plan[]
          }

          setCurrentState(updatedPlan)
          updatedState(currentState.currentPlan === defaultPlan ? plansArray[0] : currentState.currentPlan)
        } else {
          console.error('Resposta inesperada da API:', plansArray)
        }
      } catch (error) {
        console.error('Erro ao buscar planos:', error)
      }
    }

    if (currentPath === '' || currentPath === 'vip') {
      fetchPlans()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath])

  return <PlanContext.Provider value={{
    ...currentState,
    updatedState,
    setChosenPlan,
    setvirtualChip,
    setPortability,
    setSmartControl,
    setPlanIndex,
    setStep,
    setSteps,
    setLoadingPlan,
    setCurrentPurchaseLink,
  }}>{children}</PlanContext.Provider>
}