import { useState, useEffect } from 'react'
import { Grid } from "@mui/material"
import { ISettingsItem } from '../../../Settings'

export default function Timer({ name }: ISettingsItem) {
  const [hours, setHours] = useState(24)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let interval: NodeJS.Timeout

    const updateTime = () => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1)
          setSeconds(59)
        } else {
          if (hours > 0) {
            setHours(hours - 1)
            setMinutes(59)
            setSeconds(59)
          } else {
            resetTimerFunc()
          }
        }
      }
    }

    const resetTimerFunc = () => {
      setHours(24)
      setMinutes(0)
      setSeconds(0)
    }

    interval = setInterval(updateTime, 1000)

    return () => clearInterval(interval)
  }, [hours, minutes, seconds])

  return (
    <Grid container item className={name ? `${name} timer` : "timer"} xs={12}>
      <Grid item className="timerArea timerTextArea" xs={7} md={8}>
        <h1>Tempo Limitado</h1>
        <p>Ganhe 5 GB trazendo seu número</p>
      </Grid>
      <Grid item className="timerArea" xs={5} md={4}>
        <div className="squareTime hours">{String(hours).padStart(2, "0")}</div>
        :
        <div className="squareTime minutes">{String(minutes).padStart(2, "0")}</div>
        :
        <div className="squareTime seconds">{String(seconds).padStart(2, "0")}</div>
      </Grid>
    </Grid>
  )
}