import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as IOSApp } from "../../../assets/icons/IOSApp.svg"
import { ReactComponent as AndroidApp } from "../../../assets/icons/AndroidApp.svg"

export default function AppLink({ name, variables }: ISettingsItem) {
  const { text = ['https://apps.apple.com/br/app/contel-telecom/id6449952497', 'https://play.google.com/store/search?q=contel&c=apps'] } = variables || {}

  return (
    <Grid className={name ? `${name} app-link` : "app-link"}>
      <a className="appIcon" href={text[0]} target="_blank" rel="noreferrer">
        <IOSApp />
      </a>
      <a className="appIcon" href={text[1]} target="_blank" rel="noreferrer">
        <AndroidApp />
      </a>
    </Grid>
  )
}