import { Grid, Typography } from '@mui/material'
import { ISettingsItem } from '../../../../Settings'

export default function Step4({ name, variables }: ISettingsItem) {
  const { text = [] } = variables || {}

  return (
    <Grid container item className={name ? `${name} step-4 current-step` : `step-4 current-step`}>
      <Typography variant="body2" className="titleArea">{text[0]}</Typography>
      <Typography variant="body2" className="labelTextArea">{text[1]}</Typography>
      <Grid className="stepsAfterSubmit">
        <Grid item className="stepAfterSubmit">
          <Grid item className="circularLabel">1</Grid>
          <Typography variant="body2" className="circularTextLabel">{text[2]}</Typography>
        </Grid>
        <Grid item className="stepAfterSubmit">
          <Grid item className="circularLabel">2</Grid>
          <Typography variant="body2" className="circularTextLabel">{text[3]}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}