import { ISettingsItem } from "../../Settings"
import SectionItem from "./SectionItem"

type SectionProps = {
  id: string
  className: string
  elements: ISettingsItem[] | undefined
}

export default function Section({
  id,
  className,
  elements,
}: SectionProps) {
  return (
    <>
      {elements?.map((element) =>
        <SectionItem
          key={`${id}-${element.name}`}
          id={`${id}-${element.name}`}
          className={`${className}-${element.name}`}
          options={element}
        />
      )}
    </>
  )
}