import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ISettingsItem } from "../../../Settings"
import { Step1, Step2, Step3, Step4 } from './portabilitySteps'

interface StepperPortabilityProps extends ISettingsItem {
  currentStep: number
}

export default function StepperPortability({ name, variables, currentStep }: StepperPortabilityProps) {
  const [currentVariables, setCurrentVariables] = useState<{text: string[]}>()
  const { text = [] } = variables || {}
  const steps = [ Step1, Step2, Step3, Step4 ]
  const CurrentStep = steps[currentStep]

  useEffect(() => {
    const step1 = {text: [text[0]]}
    const step2 = {text: [text[1]]}
    const step3 = {text: text.slice(2, 8)}
    const step4 = {text: text.slice(8)}

    currentStep === 0 && setCurrentVariables(step1)
    currentStep === 1 && setCurrentVariables(step2)
    currentStep === 2 && setCurrentVariables(step3)
    currentStep === 3 && setCurrentVariables(step4)
  },[currentStep, text])

  return (
    <Grid container item className={name ? `${name} stepper-portability step-${currentStep + 1}` : `stepper-portability step-${currentStep + 1}`}>
      <CurrentStep variables={currentVariables} />
    </Grid>
  )
}