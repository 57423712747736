import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from "../../../Plans"
import PersonalInfo from "./Steps/PersonalInfo"
import ContactInfo from "./Steps/ContactInfo"
/* STEP 1 */
import ChoosePlanText from "../../page1/right/ChoosePlanText"
import SelectChipInfo from "../../page2/left/SelectChipInfo"
/* STEP 3 */
import AdressInfo from "./Steps/AdressInfo"
/* STEP 4 */
import PaymentStep from './Steps/PaymentStep'

// ACCESS TECHNOLOGY TEXT
const accessTechnologyTextArray = {
  text: [
    "Tecnologia de acesso",
    "Como você vai se conectar"
  ]
}

export default function Steps({ name }: ISettingsItem) {
  const { step } = usePlan()

  const stepContent = [
    <Grid container item className="step step-1" xs={12}>
      <ChoosePlanText name="access-technology-text" variables={accessTechnologyTextArray} />
      <SelectChipInfo />
    </Grid>,
    <Grid container item className="step step-2" xs={12}>
      <PersonalInfo />
      <ContactInfo />
    </Grid>,
    <Grid container item className="step step-3" xs={12}>
      <AdressInfo />
    </Grid>,
    <Grid container item className="step step-4" xs={12}>
      <PaymentStep />
    </Grid>
  ]

  return (
    <Grid container item className={name ? `${name} steps` : "steps"}>
      {stepContent[step]}
    </Grid>
  )
}