import { SVGProps } from 'react'
import { Grid } from '@mui/material'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkIcon.svg'

interface BuildYourPlanProps {
  StepIcon?: React.ComponentType<SVGProps<SVGSVGElement>>
  StatusIcon?: React.ComponentType<SVGProps<SVGSVGElement>>
  name?: string
  variables?: {
    text?: string[]
  }
}

export default function StepDefault({
  StepIcon = CheckIcon,
  StatusIcon = CheckIcon,
  name,
  variables,
}: BuildYourPlanProps) {
  const { text } = variables || {}

  return (
    <Grid container item className={name ? `${name} step-default` : "step-default"} xs={12}>
      <Grid item className="stepIcon">
        <Grid item className="iconTable">
          <StepIcon />
        </Grid>
      </Grid>
      {
        text &&
        <Grid item className="stepName">
          <h3>{text[0]}</h3>
          <p>{text[1]}</p>
        </Grid>
      }
      <Grid item className="statusIcon">
        <StatusIcon />
      </Grid>
    </Grid>
  )
}