import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { SelectPlanDetails } from '../../'
import { ReactComponent as FirstSummaryIcon } from '../../../assets/icons/firstSummaryIcon.svg'

export default function FirstSummary({ name, variables }: ISettingsItem) {
  const { portability, smartControl } = usePlan()
  const defaultTextArray = ['Bônus do Plano', 'Ganhe mais internet no seu plano']
  const { text = defaultTextArray } = variables || {}

  const portabilityVariables = {
    "text": [
      "",
      "Troque de operadora e mantenha o número",
      "",
      "",
      "",
      "",
      "",
      "none",
      "none"
    ]
  }

  const smartControlVariables = {
    "text": [
      "Controle Inteligente",
      "Pagamento mensal com fidelidade de 12 meses",
      "",
      "",
      "",
      "",
      "",
      "none",
      "none"
    ]
  }

  return (
    <Grid container item className={name ? `${name} first-summary` : "first-summary"} xs={12}>
      <Grid item className="titleArea" xs={12}>
        <Grid className="iconsArea">
          <FirstSummaryIcon />
        </Grid>
        <Grid className="textArea">
          <h1>{text[0]}</h1>
          <p>{text[1]}</p>
        </Grid>
      </Grid>
      <Grid container item className="ItemsArea" xs={12}>
        {
          portability &&
          <Grid item className="portabilityItem item">
            <SelectPlanDetails variables={portabilityVariables} />
          </Grid>
        }
        {
          smartControl &&
          <Grid item className="smartControlItem item">
            <SelectPlanDetails variables={smartControlVariables} />
          </Grid>
        }
      </Grid>
    </Grid>
  )
}