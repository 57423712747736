import { useEffect, useState } from "react"
import { Grid, Button, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"
import requests from "../../../api/client"
import { ISettingsItem } from "../../../Settings"
import { useForm } from "../../../Form"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import { ReactComponent as RightArrow } from '../../../assets/icons/rightArrow.svg'

export default function PurchaseSummary({ name, variables }: ISettingsItem) {
  const [reqLoading, setReqLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState('')
  const { currentFields, formState, setFormState } = useForm()
  const { chipInfo: { chipPrice, shippingPrice } } = useInitReq()
  const {
    currentPlan,
    chosenPlan,
    monthlyPrice,
    annualPrice,
    virtualChip,
    step,
    setStep,
    setCurrentPurchaseLink,
    currentPurchaseLink,
  } = usePlan()
  const defaultTextArray = ['Total desta compra', 'Continuar', 'Pagar']
  const { text = defaultTextArray } = variables || {}
  const navigate = useNavigate()

  useEffect(() => {
    const valor_chip = chipPrice ? parseFloat(chipPrice) : 0
    const valor_frete = shippingPrice ? parseFloat(shippingPrice) : 0
    const currentPricePlan = chosenPlan === 'monthly' ? monthlyPrice : chosenPlan === 'annual' ? annualPrice * 12 : 0
    const chip = virtualChip ? 0 : valor_chip + valor_frete

    setTotalPrice((currentPricePlan + chip).toFixed(2).replace('.', ','))
  }, [annualPrice, monthlyPrice, chosenPlan, virtualChip, chipPrice, shippingPrice])

  function cleanField(texto: string) {
    return texto.replace(/[^\d]/g, '')
  }

  const { cpfCnpj, date, externalId, email, tel, street, number, complement, neighborhood, cep, cityId } = currentFields

  let cpfOrCnpjField
  if (cpfCnpj.length === 18) {
    cpfOrCnpjField = { cnpj: cleanField(cpfCnpj) }
  } else {
    cpfOrCnpjField = { cpf: cleanField(cpfCnpj) }
  }

  let birthdayDate
  if (cpfCnpj.length === 18) {
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    birthdayDate = dayjs(formattedDate).isValid() ? formattedDate : undefined
  } else {
    birthdayDate = dayjs(date).format('YYYY-MM-DD')
  }

  /* CONFIGS DE ENVIO */
  const buyJson = {
    metodo_pagamento: "LINK",
    link_indicacao: localStorage.getItem("VipParam"),
    id_externo: externalId,
    id_plano: currentPlan.id,
    quantidade: 1,
    nome: currentFields.name,
    email,
    telefone: cleanField(tel),
    ...(birthdayDate && { data_nascimento: birthdayDate }),
    endereco: {
      rua: street,
      numero: number,
      complemento: complement,
      bairro: neighborhood,
      cep: cleanField(cep),
      id_cidade: cityId
    },
    ...cpfOrCnpjField
  }

  const activationJson = {
    metodo_pagamento: "LINK",
    link_indicacao: localStorage.getItem("VipParam"),
    id_externo: externalId,
    nome: currentFields.name,
    email,
    telefone: cleanField(tel),
    ...(birthdayDate && { data_nascimento: birthdayDate }),
    endereco: {
      rua: street,
      numero: number,
      complemento: complement,
      bairro: neighborhood,
      cep: cleanField(cep),
      id_cidade: cityId
    },
    chips: [
      {
        esim: "SIM",
        id_plano: currentPlan.id,
        ddd: cleanField(tel).substring(0, 2)
      }
    ],
    ...cpfOrCnpjField
  }

  async function fetchData() {
    try {
      const { retorno, link_pagamento, mensagem } = virtualChip ?
        await requests.activationReq(activationJson)
        :
        await requests.buyRegister(buyJson)
      if (retorno) {
        setCurrentPurchaseLink(link_pagamento)
        return { retorno, mensagem }
      } else {
        return { retorno, mensagem }
      }
    } catch (error) {
      console.error('Erro na requisição de compra:', error)
    }
  }

  const handleNav = () => {
    window.open(currentPurchaseLink, '_blank')
    navigate('/pedido-finalizado')
  }

  const handleNext = async () => {
    const nextStep =
      step === 0
      || (step === 1 && formState.form1 === true && formState.form2 === true)
      || (step === 2 && formState.form3 === true)

    // step 2
    if (nextStep) {
      setFormState('showError', false)
      if (step === 2) {
        setStep(step + 1)
        setReqLoading(true)
        try {
          const response = await fetchData()
          if (response) {
            const { retorno, mensagem } = response
            if (retorno) {
              setStep(step + 1)
            } else {
              alert(`Falha na compra: ${mensagem}`)
            }
          }
          setReqLoading(false)
        } catch (error) {
          alert(`Falha na compra: ${error}`)
          setReqLoading(false)
        }
      } else {
        setStep(step + 1)
      }
    } else {
      setFormState('showError', true)
    }
  }

  return (
    <Grid container item className={name ? `${name} purchase-summary` : "purchase-summary"} xs={12}>
      <Grid item className="priceArea">
        <h1>R$ {totalPrice}</h1>
        <p>{text[0]}</p>
      </Grid>
      <Grid item className="buttonArea">
        {
          reqLoading ?
            <CircularProgress />
          :
            <Button
              onClick={step === 3 ? handleNav : handleNext}
              style={{ textTransform: 'none' }}
            >
              {step === 3 ? text[2] : text[1]}
              <RightArrow />
            </Button>
        }
      </Grid>
    </Grid>
  )
}