import { Grid, Button } from "@mui/material"
import { ISettingsItem } from "../../../../Settings"
import { usePlan } from "../../../../Plans"

export default function PaymentStep({ name, variables }: ISettingsItem) {
  const { planConfigs, virtualChip, portability, smartControl, currentPurchaseLink } = usePlan()
  const defaultTextArray = [
    'Você está quase finalizando!',
    'Após o pagamento você receberá um SMS para terminar de configurar seu plano:',
    'Instalar e-SIM;',
    'Agendar portabilidade;',
    'Configurar contrato inteligente;',
    'Você será redirecionado para o link de pagamento.',
    'Clique aqui para pagar',
    'none'
  ]
  const { text = defaultTextArray } = variables || {}

  const handleButtonClick = () => {
    window.open(currentPurchaseLink, '_blank')
  }

  return (
    <Grid container item className={name ? `${name} payment-step` : "payment-step"}>
      <Grid item className="textArea">
        <h3 className={!planConfigs ? "nonPlanConfigs" : ""}>{text[0]}</h3>
        {
          planConfigs ?
            <Grid item className="configBonusText">
              <p>{text[1]}</p>
              <ul>
                {virtualChip && <li>- {text[2]}</li>}
                {portability && <li>- {text[3]}</li>}
                {smartControl && <li>- {text[4]}</li>}
              </ul>
            </Grid>
            :
            <h4>{text[5]}</h4>
        }
      </Grid>
      <Grid item className="buttonArea" style={{ display: text[7] }}>
        <Button
          onClick={handleButtonClick}
          style={{ textTransform: 'none' }}
        >{text[6]}</Button>
      </Grid>
    </Grid>
  )
}