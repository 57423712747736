import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"

export default function ChoosePlanText({ name, variables }: ISettingsItem) {
  const defaultTextArray = ['Escolha seu plano', 'Você decide quanto de internet você precisa']
  const { text = defaultTextArray } = variables || {}

  return (
    <Grid item className={name ? `${name} choose-plan-text` : "choose-plan-text"} xs={12}>
      <div className="textArea">
        <h1>{text[0]}</h1>
        <p>{text[1]}</p>
      </div>
    </Grid>
  )
}