import { useEffect, useState } from 'react'
import { Grid, Link } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useInitReq } from '../../../InitReq'
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as RightArrow } from '../../../assets/icons/linedRightArrow.svg'
import StepDefault from './steps/StepDefault'
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkIcon.svg'
import { ReactComponent as WhatsAppIcon } from '../../../assets/icons/whatsappIcon.svg'
/* STEP 2 */
import ESimModal from './steps/ESimModal'
import { ReactComponent as ESimIcon } from '../../../assets/icons/eSimIcon.svg'
/* STEP 3 */
import PortabilityModal from './steps/PortabilityModal'
import { ReactComponent as PortabilityIcon } from '../../../assets/icons/portabilityIcon.svg'
/* STEP 4 */
import SmartControl from './steps/SmartControl'
import { ReactComponent as SmartControlIcon } from '../../../assets/icons/smartControlIcon.svg'

export default function StepperConfig({ name, variables }: ISettingsItem) {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUserConfigs, loadingUserConfigs } = useInitReq()
  const [currentPath, setCurrentPath] = useState<string>()
  const [step2, setStep2] = useState('active')
  const [step3, setStep3] = useState('disabled')
  const [step4, setStep4] = useState('disabled')
  const [step2Modal, setStep2Modal] = useState(false)
  const [step3Modal, setStep3Modal] = useState(false)
  const [step4Modal, setStep4Modal] = useState(false)

  const defaultWhatsAppNumber = '558007173774'
  const defaultWhatsAppText = 'Ol%C3%A1!%20Gostaria%20de%20tirar%20algumas%20d%C3%BAvidas.'

  const defaultTextArray = [
    'none', // índice 0
    '', // índice 1 - a URL do WhatsApp será construída dinamicamente
    'Preciso de ajuda', // índice 2

    // eSIM
    'eSIM', // índice 3
    'Instalação do eSIM', // índice 4
    'Acesse o PDF abaixo para iniciar o processo de configuração', // índice 5
    currentUserConfigs?.esim_pdf ? currentUserConfigs?.esim_pdf : '', // índice 6

    // Portabilidade
    'PORTABILIDADE', // índice 7
    'Agendar Portabilidade', // índice 8
    'Não se preocupe, o processo só será iniciado assim que o seu chip Contel for ativado.', // índice 9
    'Qual telefone deverá ser transferido para a Contel?', // índice 10
    'Este número atualmente é de qual operadora?', // índice 11
    'Confirme que está tudo certo', // índice 12
    'Connect', // índice 13 - nome da marca
    'Saindo', // índice 14
    'Entrando', // índice 15
    'Atenção', // índice 16
    'Ao finalizar confirmo que este número está ativo e também registrado em meu CPF ou CNPJ', // índice 17
    'Tudo certo', // índice 18
    'Em até 03 dias depois da ativação do CHIP seu número será migrado', // índice 19
    'Quando seu CHIP Físico chegar, ative-o normalmente', // índice 20
    'Em seguida, responda SIM para o SMS de Confirmação' // índice 21
    // O índice 22 será o número do WhatsApp, fornecido em variables.text[22]
  ]

  const { text } = variables || {}
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  // Obter o número do WhatsApp de variables.text[22], ou usar o padrão
  const whatsappNumber = text && text.length > 22 && text[22] ? text[22] : defaultWhatsAppNumber
  const whatsappText = defaultWhatsAppText
  mergedArray[1] = `https://wa.me/${whatsappNumber}?text=${whatsappText}`

  /* STEP 1 */
  const buildYourPlanText = { text: ['Montar seu plano', 'Plano concluído com sucesso' ] }
  /* STEP 2 */
  const installEsimText = { text: ['Instalar eSIM', 'Complete a instalação do CHIP'] }
  const esimModalTextArray = {
    text: [
      mergedArray[3],
      mergedArray[4],
      mergedArray[5],
      mergedArray[6],
      mergedArray[1],
      mergedArray[2],
    ]
  }
  const modalClickEsim = () => {
    setStep2('completed')
    setStep2Modal(false)
    if (configList.length > 0) {
      const nextIndex = configList.findIndex((item) => item === 'step2') + 1
      if (configList[nextIndex] === 'step3') {
        step3 !== 'completed' && setStep3('active')
      } else if (configList[nextIndex] === 'step4') {
        step4 !== 'completed' && setStep4('active')
      } else {
        if (currentPath !== 'pedido-finalizado') navigate('/pedido-finalizado')
      }
    }
  }
  /* STEP 3 */
  const schedulePortability = { text: ['Agendar Portabilidade', 'Traga seu número para a Contel'] }
  const portabilityModalTextArray = {
    text: [
      mergedArray[7],
      mergedArray[8],
      mergedArray[9],
      mergedArray[10],
      mergedArray[11],
      mergedArray[12],
      mergedArray[13],
      mergedArray[14],
      mergedArray[15],
      mergedArray[16],
      mergedArray[17],
      mergedArray[18],
      mergedArray[19],
      mergedArray[20],
      mergedArray[21],
      mergedArray[1],
      mergedArray[2],
    ]
  }
  const modalClickPortability = () => {
    setStep3('completed')
    setStep3Modal(false)
    if (configList.length > 0) {
      const nextIndex = configList.findIndex((item) => item === 'step3') + 1
      if (configList[nextIndex] === 'step4') {
        step4 !== 'completed' && setStep4('active')
      } else {
        if (currentPath !== 'pedido-finalizado') navigate('/pedido-finalizado')
      }
    }
  }
  /* STEP 4 */
  const smartControlContract = { text: ['Controle  Inteligente', 'Configure seu contrato'] }
  const modalClickSmartControl = () => {
    setStep4('completed')
    setStep4Modal(false)
    if (currentPath !== 'pedido-finalizado') navigate('/pedido-finalizado')
  }
  /* STEP 5 */
  const whatsappConfig = {
    text: [
      'Dúvidas?',
      'Atendimento 24h pelo WhatsApp'
    ]
  }

  const configList: string[] = []
  const esim = currentUserConfigs?.esim
  const portability = currentUserConfigs?.portabilidade
  const smartControl = false
  if (esim) configList.push('step2')
  if (portability) configList.push('step3')
  if (smartControl) configList.push('step4')

  const modalOpen = step2Modal || step3Modal || step4Modal ? true : false
  if (modalOpen) {
    document.documentElement.style.overflowY = 'hidden'
  } else {
    document.documentElement.style.overflowY = ''
  }

  useEffect(() => {
    setStep3(esim ? 'disabled' : 'active')
    setStep4(esim || portability ? 'disabled' : 'active')
  }, [esim, portability])

  useEffect(() => {
    const param = location.pathname.split('/')[1]
    setCurrentPath(param)
  }, [location])

  return (
    <Grid container item className={name ? `${name} stepper-config` : "stepper-config"}>
      <Grid container item className="completed step step-1" xs={12}>
        <StepDefault
          name="build-your-plan"
          variables={buildYourPlanText}
          StepIcon={CheckIcon}
          StatusIcon={CheckIcon}
        />
      </Grid>
      
      {!loadingUserConfigs && (
        <>
          {
            esim &&
            <>
              <Grid
                container
                item
                className={`${step2} step step-2`}
                xs={12}
                onClick={
                  step2 !== 'disabled' ? () => {
                    setStep2Modal(true)
                  } : () => {}}
              >
                <StepDefault
                  name="install-esim"
                  variables={installEsimText}
                  StepIcon={ESimIcon}
                  StatusIcon={step2 === 'completed' ? CheckIcon : RightArrow}
                />
              </Grid>
              {step2Modal && <ESimModal variables={esimModalTextArray} onClick={modalClickEsim} />}
            </>
          }
          {
            portability &&
            <>
              <Grid
                container
                item
                className={`${step3} step step-3`}
                xs={12}
                onClick={
                  step3 !== 'disabled' ? () => {
                    setStep3Modal(true)
                  } : () => {}}
              >
                <StepDefault
                  name="schedule-portability"
                  variables={schedulePortability}
                  StepIcon={PortabilityIcon}
                  StatusIcon={step3 === 'completed' ? CheckIcon : RightArrow}
                />
              </Grid>
              {step3Modal && (
                <PortabilityModal
                  variables={portabilityModalTextArray}
                  onClick={modalClickPortability}
                  closeModal={() => setStep3Modal(false)}
                />
              )}
            </>
          }
          {
            smartControl &&
            <>
              <Grid
                container
                item
                className={`${step4} step step-4`}
                xs={12}
                onClick={
                  step4 !== 'disabled' ? () => {
                    setStep4Modal(true)
                  } : () => {}}
              >
                <StepDefault
                  name="smart-control-contract"
                  variables={smartControlContract}
                  StepIcon={SmartControlIcon}
                  StatusIcon={step4 === 'completed' ? CheckIcon : RightArrow}
                />
              </Grid>
              {step4Modal && <SmartControl onClick={modalClickSmartControl} />}
            </>
          }
        </>
      )}

      <Link
        className="active step step-5"
        style={{ display: mergedArray[0] }}
        href={mergedArray[1]}
        target="_blank"
        rel="noreferrer"
      >
        <StepDefault
          name="whatsapp-config"
          variables={whatsappConfig}
          StepIcon={WhatsAppIcon}
          StatusIcon={RightArrow}
        />
      </Link>
    </Grid>
  )
}