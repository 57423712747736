async function requestAssets(url: string): Promise<Response> {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error(`Erro na chamada à url ${url}: ${response.status} - ${response.statusText}`)

    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}

export async function GetSettings(app: string): Promise<object> {
  const url = `/assets/${app}/settings.json`
  const response = await requestAssets(url)

  return await response.json()
}

export async function GetStaticContent({ app, file }: { app: string, file?: string }): Promise<string> {
  if (file === undefined || file === '') throw new Error('Arquivo estático não informado')

  const url = `/assets/${app}/content/${file}`
  const response = await requestAssets(url)

  return await response.text()
}