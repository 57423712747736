import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Box, Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import logoDefault from '../../../assets/images/logoDefault.svg'

interface LogoListProps {
  url: string
  render: boolean
}

export default function Heading({ name, variables }: ISettingsItem) {
  const [logoList, setLogoList] = useState<LogoListProps[]>()
  const navigate = useNavigate()
  const location = useLocation()
  const { planIndex } = usePlan()
  const { image = [logoDefault] } = variables || {}

  useEffect(() => {
    if (image.length > 1) {
      setLogoList(image.map((url, index) => ({ url, render: index === planIndex ? true : false })))
    } else {
      setLogoList(image.map((url) => ({ url, render: true })))
    }
    // eslint-disable-next-line
  },[planIndex, location.pathname])

  return (
    <Grid
      item
      className={name ? `${name} heading` : "heading"}
      onClick={() => navigate('/')}
      sx={{ cursor: 'pointer', zIndex: '100' }}
    >
      {logoList?.map(({url, render}, index) => (
        <Box
          key={index}
          component="img"
          alt="Logo"
          src={url}
          sx={{ display: render ? 'block' : 'none' }}
        />
      ))}
    </Grid>
  )
}