import { ElementType, useEffect, useRef } from 'react'
import { Grid, Hidden, TextField } from '@mui/material'
import { ISettingsItem } from '../../../../Settings'
import MaskedTextField, { MaskedTextFieldProps } from '../../../MaskedTextField'
import { useForm } from '../../../../Form'
import { ChoosePlanText } from '../../..'

interface fieldProps extends Omit<MaskedTextFieldProps, "mask"> {
  Component: ElementType
  mask?: string | string[]
}

export default function ContactInfo({ name, variables }: ISettingsItem) {
  const { currentFields, formState: { showError }, setCurrentFields, setFormState } = useForm()
  const fieldsUnfilled = useRef([] as string[])
  const defaultTextArray = [
    'Informações de Contato',
    'Como podemos falar com você',
    'E-mail',
    'DDD',
    'Telefone',
  ]
  const { text = defaultTextArray } = variables || {}

  const fields: fieldProps[] = [
    {
      Component: TextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "email",
      id: "outlined-email-input",
      value: currentFields.email,
      label: text[2],
      type: "email",
    },
    {
      Component: MaskedTextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "tel",
      id: "outlined-tel-input",
      value: currentFields.tel,
      label: text[4],
      type: "tel",
      mask: "(##) #####-####"
    }
  ]

  const renderInputs = ({
    Component,
    required,
    helperText,
    name,
    id,
    value,
    label,
    type,
    mask
  }: fieldProps, index: number, mob: boolean) => {
    return <Component
      key={name}
      required={required}
      error={showError && fieldsUnfilled.current.includes(name) && required}
      helperText={showError && fieldsUnfilled.current.includes(name) && helperText}
      name={name}
      id={id}
      value={value}
      onChange={setCurrentFields}
      fullWidth
      label={label}
      type={type}
      mask={mask}
      size={mob ? 'medium' : 'small'}
    />
  }

  useEffect(() => {
    fieldsUnfilled.current = fields
      .filter(field => field.required)
      .reduce(
        (agg: string[], { name, value }) => {
          if (["", null, undefined].includes(value)) agg.push(name)
          return agg
        }, []
      )

    setFormState('form2', (fieldsUnfilled.current.length === 0))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFields.email, currentFields.tel])

  const fieldsName = fields.filter(field => ['email', 'tel'].includes(field.name))

  return (
    <Grid container item className={name ? `${name} contact-info form` : "contact-info form"} xs={12} sx={{ display: 'flex', gap: '20px' }}>
      <Grid item className="textArea">
        <ChoosePlanText name="contact-info-text" variables={{ text }} />
      </Grid>
      <Hidden mdUp>
        <Grid item className="formArea" xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {
            fieldsName.map((field, index) => renderInputs(field, index, true))
          }
        </Grid>
      </Hidden>
      <Hidden only={['xs', 'xl']}>
        <Grid item className="formArea" xs={12}>
          {
            fieldsName.map((field, index) => renderInputs(field, index, false))
          }
        </Grid>
      </Hidden>
      <Hidden xlDown>
        <Grid item className="formArea" xs={12}>
          {
            fieldsName.map((field, index) => renderInputs(field, index, true))
          }
        </Grid>
      </Hidden>
    </Grid >
  )
}