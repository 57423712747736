import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"

export default function Description({ name, variables }: ISettingsItem) {
  const { text = [
    'Agora você faz parte da revolução da comunicação no Brasil! Aproveite seu novo plano.'
  ] } = variables || {}

  return (
    <Grid item className={name ? `${name} description` : "description"}>
      <p>
        {text[0]}
      </p>
    </Grid>
  )
}