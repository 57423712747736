import { Grid, Button } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as DownArrow } from '../../../assets/icons/downArrow.svg'

export default function Slogan({ name, variables }: ISettingsItem) {
  const { text = ['Descomplica'] } = variables || {}

  const scrollToSelectPlan = () => {
    const selectPlanElement = document.getElementsByClassName('choose-plan-text');
    if (selectPlanElement) {
      const offset = 30;
      const elementPosition = selectPlanElement[0].getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth',
      });
    }
  }

  return (
    <Grid item className={name ? `${name} uncomplicate-button` : "uncomplicate-button"}>
      <Button onClick={scrollToSelectPlan} style={{ textTransform: 'none' }}>
        {text[0]}
        <DownArrow />
      </Button>
    </Grid>
  )
}
