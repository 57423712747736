import { PropsWithChildren, createContext, useCallback, useContext, useState, ChangeEvent } from "react"

export interface CompaniesTelProps {
  id?: number
  descricao?: string
}

export interface CompaniesTelData {
  retorno: boolean
  data: CompaniesTelProps[]
}

/***** FIELDS DO FORMULÁRIO *****/
export interface FormFields {
  // FIELDS 2
  name: string
  cpfCnpj: string
  date: any
  email: string
  tel: string
  // FIELDS 3
  cep: string,
  city?: string,
  cityId?: number,
  state?: string,
  uf?: string,
  street: string,
  neighborhood: string,
  number: string,
  complement: string,
  reference: string,
  externalId?: string
  // PORTABILITY FIELDS
  companiesTel: CompaniesTelProps[] | []
  telToTransfer: string
  telCompany?: number
  companyLabel?: string
}

interface FormState {
  form1: boolean
  form2: boolean
  form3: boolean
  showError: boolean
  step1Error: boolean
  step1Invalid: boolean
  step2Error: boolean
}

const defaultForm: FormFields = {
  // FIELDS 2
  name: '',
  cpfCnpj: '',
  date: null, // dayjs(date).format('YYYY-MM-DD')
  email: '',
  tel: '',
  // FIELDS 3
  cep: '',
  city: undefined,
  cityId: undefined,
  state: undefined,
  uf: undefined,
  street: '',
  neighborhood: '',
  number: '',
  complement: '',
  reference: '',
  externalId: '',
  // PORTABILITY FIELDS
  companiesTel: [],
  telToTransfer: '',
  telCompany: undefined,
  companyLabel: undefined
}

const defaultFormState: FormState = {
  form1: false,
  form2: false,
  form3: false,
  showError: false,
  step1Error: false,
  step1Invalid: false,
  step2Error: false,
}

// >>> CONFIGS DO PROVIDER
export interface FormsContext {
  currentFields: FormFields
  formState: FormState
}

interface updateContext extends FormsContext {
  setCurrentFields: (event: ChangeEvent<HTMLInputElement>) => void
  setModelledField: (name: string, value: any) => void
  setFormState: (form: string, value: boolean) => void
}

const defaultFormContext: updateContext = {
  currentFields: defaultForm,
  formState: defaultFormState,
  setCurrentFields: (event: ChangeEvent<HTMLInputElement>) => {},
  setModelledField: (name: string, value: any) => {},
  setFormState: (form: string, value: boolean) => {},
}

const FormContext = createContext<updateContext>(defaultFormContext)

export function useForm() {
  return useContext(FormContext)
}

export function FormProvider({ children }: PropsWithChildren) {
  const [currentState, setCurrentState] = useState<FormFields>(defaultForm)
  const [currentFormState, setCurrentFormState] = useState<FormState>(defaultFormState)

  const setCurrentFields = useCallback(({ currentTarget: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setCurrentState({
      ...currentState,
      [name]: value
    })
  }, [currentState])

  const setModelledField = useCallback((name: string, value: any) => {
    setCurrentState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [])

  const setFormState = useCallback((form: string, value: boolean) => {
    setCurrentFormState((prevState) => ({
      ...prevState,
      [form]: value
    }))
  }, [])

  return <FormContext.Provider value={{
    currentFields: currentState,
    formState: currentFormState,
    setCurrentFields,
    setModelledField,
    setFormState,
  }}>{children}</FormContext.Provider>
}