import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from "../../../Plans"
import { ReactComponent as ThinCheckIcon } from "../../../assets/icons/thinCheckIcon.svg"

export default function SloganPg4({ name, variables }: ISettingsItem) {
  const defaultTextArray = ['Tudo certo,', 'pode', 'aproveitar sua conta', 'block']
  const virtualChipArray = ['Você receberá um', 'SMS', 'para configurar seu plano', 'block']
  const { text } = variables || {}
  const { virtualChip } = usePlan()

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  return (
    <Grid container item className={name ? `${name} slogan-pg-4` : "slogan-pg-4"}>
      <Grid item className="iconArea" style={{ display: mergedArray[3] }}>
        <ThinCheckIcon />
      </Grid>
      <Grid item className="textArea">
        <h1>
          {virtualChip ? virtualChipArray[0] : mergedArray[0]}
          <span> {virtualChip ? virtualChipArray[1] : mergedArray[1]} </span>
          {virtualChip ? virtualChipArray[2] : mergedArray[2]}
        </h1>
      </Grid>
    </Grid>
  )
}