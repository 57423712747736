import { Grid, Button, Link } from "@mui/material"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { ReactComponent as ESimIcon } from '../../../../assets/icons/eSimIcon.svg'
import ChoosePlanText from "../../../page1/right/ChoosePlanText"
import { ReactComponent as HelpIcon } from '../../../../assets/icons/helpIcon.svg'

interface EsimModalProps {
  onClick: () => void
  name?: string
  variables?: { text?: string[] }
}

export default function ESimModal({ onClick, name, variables }: EsimModalProps) {
  const { text = [] } = variables || {}
  const esimModalTextArray = { text: [ text[1], text[2] ] }

  return (
    <Grid className="blackShadow">
      <Grid container item className={name ? `${name} esim-modal modal` : "esim-modal modal"}>
        <Grid item className="detailArea">
          <Grid className="eSimIcon">
            <ESimIcon />
          </Grid>
          <Grid className="textIcon">
            {text[0]}
          </Grid>
        </Grid>
        <Grid item className="textArea">
          <ChoosePlanText name="esim-modal-text" variables={esimModalTextArray} />
        </Grid>
        
        <Grid container item className="mainSectionModal">
          <Grid item className="pdfBtnArea">
            <Link href={text[3]} target='_blank'>
              <PictureAsPdfIcon /> PDF
            </Link>
          </Grid>
        </Grid>

        <Link href={text[4]} target="_blank" className="helpArea">
          <HelpIcon /> {text[5]}
        </Link>
        <Grid item className="btnArea">
          <Button onClick={onClick}>
            Pronto
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
