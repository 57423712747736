import { Grid, Typography } from '@mui/material'
import { ISettingsItem } from '../../../../Settings'
import { useForm } from '../../../../Form'
import { ReactComponent as LeftArrow } from '../../../../assets/icons/leftArrow.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/closeCircleIcon.svg'

export default function Step3({ name, variables }: ISettingsItem) {
  const { currentFields } = useForm()
  const { text = [] } = variables || {}

  return (
    <Grid container item className={name ? `${name} step-3 current-step` : `step-3 current-step`}>
      <Grid item className="circularLabel">3</Grid>
      <Typography variant="body2" className="labelStepArea">{text[0]}</Typography>
      <Grid container className="transferArea">
        <Grid item className="prevNextCompany">
          <Grid item className="prevNextCompanyLabel">
            <CloseIcon /> {currentFields.companyLabel}
          </Grid>
          <Typography variant="body2">{text[2]}</Typography>
        </Grid>
        <Grid item className="rightArrow">
          <LeftArrow />
        </Grid>
        <Grid item className="prevNextCompany">
          <Grid item className="prevNextCompanyLabel nextCompany">
            <CheckIcon /> {text[1]}
          </Grid>
          <Typography variant="body2">{text[3]}</Typography>
        </Grid>
      </Grid>
      <Grid item className="currentStepText">
        <Typography variant="body2" className="attentionLabel">{text[4]}</Typography>
        <Typography variant="body2" className="textArea">{text[5]}</Typography>
      </Grid>
    </Grid>
  )
}