import { FunctionComponent, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import slugify from 'slugify'
import { ISettingsPage, useSettings } from '../Settings'
import LayoutDefault from './default/LayoutDefault'
import { Loading } from '../components'
import Page404 from '../components/Page404'

import './index.css'

export interface LayoutComponent {
  page: ISettingsPage
  pageClass: string
}

const layouts: { [key: string]: FunctionComponent<LayoutComponent> } = {
  default: LayoutDefault
}

export default function Layout() {
  const { loading, app, pages } = useSettings()
  const [cssLoaded, setLoaded] = useState(false)

  if (pages === undefined || app === undefined || loading) {
    return <Loading />
  }

  const customStylesheet = `/assets/${app}/style.css`

  const customCssLoaded = () => {
    setLoaded(true)
  }

  return <>
    {
      cssLoaded &&
      <Routes>
        {
          pages.map((page) => {
            let layout = page.layout

            if (layout === undefined || !Object.keys(layouts).includes(layout)) layout = 'default'

            const Component = layouts[layout]

            const pageRoute = slugify(page.route.replace(':', '-'))

            const pageClass = pageRoute === '' ? 'home' : pageRoute

            return <Route key={page.route} path={page.route} element={<Component page={page} pageClass={pageClass} />} />
          })
        }
        <Route path="/*" element={<Page404 />} />
      </Routes>
    }
    <link id="customStylesheet" onLoad={customCssLoaded} rel="stylesheet" href={customStylesheet} />
  </>
}