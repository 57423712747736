import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { useInitReq } from "../../../InitReq"
import CheckboxDefault from '../../CheckboxDefault'

export default function SelectChipInfo({ name, variables }: ISettingsItem) {
  const { setvirtualChip, virtualChip } = usePlan()
  const { chipInfo: { chipPrice, shippingPrice } } = useInitReq()
  const { text } = variables || {}

  const defaultTextArray = [
    'CHIP Físico',
    '(Compra Única)',
    'Chip que você insere em seu aparelho',
    'CHIP Virtual',
    'Chip para celulares modernos (Ex. Iphone 12)'
  ]

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const handleCheckboxChange = (chipType: string) => {
    setvirtualChip(chipType === 'virtual')
  }

  // Calcula o valor total do chip físico (chip + frete)
  const totalChipPrice = chipPrice && shippingPrice
    ? (parseFloat(chipPrice) + parseFloat(shippingPrice)).toFixed(2).replace('.', ',')
    : '0,00'

  return (
    <Grid container item className={name ? `${name} select-chip-info` : "select-chip-info"} xs={12}>
      <Grid container item className="chip physicalChip" xs={12}>
        <Grid container item className="checkArea">
          <CheckboxDefault
            checked={!virtualChip}
            onChange={() => handleCheckboxChange('physical')}
          />
          <Grid item className="physicalVirtualTextArea">
            <h1>{mergedArray[0]} <span>{mergedArray[1]}</span></h1>
            <p>{mergedArray[2]}</p>
          </Grid>
        </Grid>
        <Grid item className="physicalVirtualPriceArea">
          <h1>+ R$ {totalChipPrice}</h1>
        </Grid>
      </Grid>
      <Grid container item className="chip virtualChip" xs={12}>
        <Grid container item className="checkArea">
          <CheckboxDefault
            checked={virtualChip}
            onChange={() => handleCheckboxChange('virtual')}
          />
          <Grid item className="physicalVirtualTextArea">
            <h1>{mergedArray[3]}</h1>
            <p>{mergedArray[4]}</p>
          </Grid>
        </Grid>
        <Grid item className="physicalVirtualPriceArea">
          <h1>+ R$ 0,00</h1>
        </Grid>
      </Grid>
    </Grid>
  )
}