import { Grid, Skeleton } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as ThinCheckIcon } from "../../../assets/icons/thinCheckIcon.svg"
import { useInitReq } from "../../../InitReq"
import { useEffect, useState } from "react"

export default function SloganPg3({ name, variables }: ISettingsItem) {
  const [userName, setUserName] = useState<string>()
  const { currentUserConfigs, loadingUserConfigs } = useInitReq()

  useEffect(() => {
    const firstName = currentUserConfigs?.nome.split(' ')[0]
    setUserName(firstName)
  },[currentUserConfigs?.nome])

  const defaultTextArray = ['Bem-vindo a', 'Connect,', userName, 'none']
  const { text } = variables || {}
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  return (
    <Grid container item className={name ? `${name} slogan-pg-3` : "slogan-pg-3"}>
      <Grid item className="iconArea" style={{ display: mergedArray[3] }}>
        <ThinCheckIcon />
      </Grid>
      <Grid item className="textArea">
        {
          loadingUserConfigs ? (
            <Grid className="skltn-slogan">
              <Skeleton className="skltn-h1 skltn-1" variant="text" />
              <Skeleton className="skltn-h1 skltn-2" variant="text" />
            </Grid>
          ) : (
            <h1>
              {mergedArray[0]}
              <span> {mergedArray[1]} </span>
              {mergedArray[2]}
            </h1>
          )
        }
      </Grid>
    </Grid>
  )
}