import { Checkbox, CheckboxProps, styled } from '@mui/material'
import { ReactComponent as CheckedboxIcon } from '../assets/icons/checkedboxIcon.svg'
import { ReactComponent as CheckboxIcon } from '../assets/icons/checkboxIcon.svg'

const StyledCheckbox = styled((props: CheckboxProps & { checked?: boolean }) => (
  <Checkbox
    checkedIcon={<CheckedboxIcon />}
    icon={<CheckboxIcon />}
    {...props}
  />
))(() => ({
  '&.MuiButtonBase-root.MuiCheckbox-root': {
    padding: '0 5px 1px 0',
  },
  '&.MuiButtonBase-root.MuiCheckbox-root svg': {
    width: '20px',
  },
}))

interface CheckboxDefaultProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
}

export default function CheckboxDefault({ checked, onChange, ...props }: CheckboxDefaultProps) {

  return (
    <StyledCheckbox
      checked={checked}
      onChange={() => onChange && onChange(!checked)}
      {...props}
    />
  )
}
