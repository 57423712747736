import { Grid, Skeleton } from '@mui/material'
import { ISettingsItem } from '../../../Settings'
import { usePlan } from '../../../Plans'

export default function SelectPlan({ name, variables }: ISettingsItem) {
  const { plans, currentPlan, updatedState, setPlanIndex, planIndex } = usePlan()
  const selectedPlan = currentPlan.plano
  const defaultTextArray = ['Start', 'Flexi', 'Mega', 'Ultra', 'Tour', 'PRO', 'block']
  const { text } = variables || {}

  const mergedArray = text !== undefined
    ? text
    : defaultTextArray

  const splitPlan = selectedPlan.match(/(\d+)\s*([^\d]+)/) || []
  const splitPlanGB = splitPlan.length > 1 && splitPlan[splitPlan.length - 1].split('')
  const splitPlanG = Array.isArray(splitPlanGB) ? splitPlanGB[0] : ''
  const splitPlanB = Array.isArray(splitPlanGB) ? splitPlanGB[1] : ''

  const handleButtonClick = (index: any, plan: any) => {
    updatedState(plan)
    setPlanIndex(index)
  }

  return (
    <Grid container item className={name ? `${name} select-plan` : "select-plan"} xs={12}>
      <Grid container item className="selectArea" xs={12}>
        {plans.length > 0 ? (
          <Grid container item className="containerCarousel">
            {plans.map((plan, index) => {
              const splitPlanString = plan.plano.match(/(\d+)\s*([^\d]+)/) || []

              return (
                <button
                  key={index}
                  data-button-ligacoes={plan.ligacoes}
                  data-button-nome-plano={plan.nome_plano}
                  onClick={() => handleButtonClick(index, plan)}
                  className={index === planIndex ? 'active-button' : ''}
                >
                  <h1>{splitPlanString[1]}</h1>
                  <p>{splitPlanString[2]}</p>
                </button>
              )
            })}
            <button
              className="disabledButton"
              disabled={true}
            >
              <h1>100</h1>
              <p>GB</p>
            </button>
            <Grid item sx={{display:mergedArray[6]}} className="buttonLabel">
              <p>{mergedArray[5]}</p>
            </Grid>
          </Grid>
        ) : (
          <Grid container item className="containerCarousel">
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
            <Skeleton className="skltn-button" variant="circular" />
          </Grid>
        )}
      </Grid>
      {plans.length ? (
        <Grid item className="planTextArea" xs={12}>
          <h1 className="labelPlan">
            {mergedArray[plans.findIndex(plan => plan.plano === selectedPlan)]} {' '}
          </h1>
          <h1>{splitPlan?.[1]}{splitPlanG.toUpperCase()}{splitPlanB.toLowerCase()}</h1>
        </Grid>
      ) : (
        <Grid item className="planTextArea" xs={12}>
          <Skeleton className="skltn-labelPlan" variant="text" width="50%" sx={{ padding: '0.25rem 0' }} />
        </Grid>
      )}
    </Grid>
  )
}
