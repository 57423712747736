import { ChangeEvent } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useForm } from '../../../../Form'
import { ISettingsItem } from '../../../../Settings'
import MaskedTextField from '../../../MaskedTextField'

export default function Step1({ name, variables }: ISettingsItem) {
  const { currentFields, formState: { step1Error, step1Invalid }, setModelledField } = useForm()
  const { text = [] } = variables || {}
  const helperText = step1Error ? 'Campo obrigatório!' : step1Invalid ? 'Campo inválido!' : undefined

  const theme = useTheme()
  const smallInput = useMediaQuery(theme.breakpoints.only('md'))

  return (
    <Grid container item className={name ? `${name} step-1 current-step` : `step-1 current-step`}>
      <Grid item className="circularLabel">1</Grid>
      <Typography variant="body2" className="labelStepArea">{text[0]}</Typography>
      <MaskedTextField
        required
        error={step1Error || step1Invalid}
        helperText={helperText}
        name="tel"
        id="outlined-tel-input"
        value={currentFields.telToTransfer}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setModelledField('telToTransfer', event.target.value);
        }}
        label="(00) 00000-0000"
        type="tel"
        mask="(##) #####-####"
        size={smallInput ? 'small' : 'medium'}
        sx={{ width: '65%' }}
      />
    </Grid>
  )
}