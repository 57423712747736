import { ElementType, useEffect, useRef } from "react"
import { Grid, Hidden, TextField } from "@mui/material"
import dayjs from "dayjs"
import { DateField } from '@mui/x-date-pickers/DateField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MaskedTextField, { MaskedTextFieldProps } from "../../../MaskedTextField"
import { ISettingsItem } from "../../../../Settings"
import { useForm } from "../../../../Form"
import { ChoosePlanText } from '../../..'

interface fieldProps extends Omit<Omit<MaskedTextFieldProps, "mask">, "value"> {
  Component: ElementType
  value: string | any
  mask?: string | string[]
  clearable?: boolean
  format?: string
  md?: number
}

export default function PersonalInfo({ name, variables }: ISettingsItem) {
  const { currentFields, formState: { showError }, setCurrentFields, setModelledField, setFormState } = useForm()
  const fieldsUnfilled = useRef([] as string[])
  const defaultTextArray = [
    'Informações Pessoais',
    'Como podemos te identificar',
    'Nome Completo',
    'CPF ou CNPJ',
    'Data de Nascimento',
  ]
  const { text = defaultTextArray } = variables || {}

  const fields: fieldProps[] = [
    {
      Component: TextField,
      required: true,
      error: false,
      helperText: "Campo obrigatório!",
      name: "name",
      id: "outlined-name-input",
      value: currentFields.name,
      onChange: setCurrentFields,
      label: text[2],
      type: "name",
    },
    {
      Component: MaskedTextField,
      required: true,
      error: false,
      helperText: "Campo obrigatório!",
      name: "cpfCnpj",
      id: "outlined-cpf-cnpj-input",
      value: currentFields.cpfCnpj,
      onChange: setCurrentFields,
      label: text[3],
      type: "tel",
      mask: ["###.###.###-##", "##.###.###/####-##"],
      md: 6,
    },
    {
      Component: DateField,
      required: currentFields.cpfCnpj.length === 18 ? false : true,
      helperText: "Campo obrigatório!",
      name: "date",
      id: "outlined-date-input",
      value: currentFields.date,
      onChange: value => value && setModelledField("date", value),
      label: text[4],
      clearable: true,
      format: "DD/MM/YYYY",
      md: 6,
    }
  ]

  const renderInputs = ({
    Component,
    required,
    helperText,
    name,
    id,
    value,
    onChange,
    label,
    type,
    mask,
    clearable,
    format,
  }: fieldProps, index: number, mob: boolean) => {
    return <Component
      key={index}
      required={required}
      error={showError && fieldsUnfilled.current.includes(name) && required}
      helperText={showError && fieldsUnfilled.current.includes(name) && helperText}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      fullWidth
      label={label}
      type={type}
      mask={mask}
      clearable={clearable}
      format={format}
      size={mob ? 'medium' : 'small'}
    />
  }

  useEffect(() => {
    fieldsUnfilled.current = fields
      .filter(field => field.required)
      .reduce(
        (agg: string[], { name, value }) => {
          let processedValue = value

          if (name === "date") {
            processedValue = dayjs(value).format('YYYY-MM-DD')
          }

          if (["", null, undefined, "Invalid Date"].includes(processedValue)) agg.push(name)

          return agg
        }, []
      )

    setFormState('form1', (fieldsUnfilled.current.length === 0))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFields.name, currentFields.cpfCnpj, currentFields.date])


  const beforeFracture = fields.filter(field => ['name'].includes(field.name))
  const fracture = fields.filter(field => ['cpfCnpj', 'date'].includes(field.name))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container item className={name ? `${name} personal-info form` : "personal-info form"} xs={12} sx={{ display: 'flex', gap: '20px' }}>
        <Grid item className="textArea">
          <ChoosePlanText name="personal-info-text" variables={{ text }} />
        </Grid>
        <Hidden mdUp>
          <Grid container item className="formArea" xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {
              fields.map((field, index) => renderInputs(field, index, true))
            }
          </Grid>
        </Hidden>
        <Hidden only={['xs', 'xl']}>
          <Grid container item className="formArea" xs={12}>
            {
              beforeFracture.map((field, index) => renderInputs(field, index, false))
            }
            <Grid item className="fractArea">
              {
                fracture.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, false)}</Grid>)
              }
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xlDown>
          <Grid container item className="formArea" xs={12}>
            {
              beforeFracture.map((field, index) => renderInputs(field, index, true))
            }
            <Grid item className="fractArea">
              {
                fracture.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, true)}</Grid>)
              }
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </LocalizationProvider>
  )
}