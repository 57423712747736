import { useEffect, useState } from "react"
import { Grid, Button, Link, CircularProgress } from "@mui/material"
import { useForm } from "../../../../Form"
import requests from "../../../../api/client"
import ChoosePlanText from "../../../page1/right/ChoosePlanText"
import StepperPortability from "../StepperPortability"
import { ReactComponent as PortabilityIcon } from '../../../../assets/icons/portabilityIcon.svg'
import { ReactComponent as HelpIcon } from '../../../../assets/icons/helpIcon.svg'
import { ReactComponent as LeftArrow } from '../../../../assets/icons/leftArrow.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/thinCheckIcon.svg'
import { useInitReq } from "../../../../InitReq"

interface EsimModalProps {
  name?: string
  onClick: () => void
  closeModal: () => void
  variables?: {
    text?: string[]
  }
}

export default function PortabilityModal({ name, onClick, closeModal, variables }: EsimModalProps) {
  const { currentFields, setFormState } = useForm()
  const { currentUserConfigs, userConfigParam } = useInitReq()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const { text = [] } = variables || {}
  const portabilityModalTextArray = { text: [text[1], text[2]] }
  const portabilityStepperTextArray = { text: text.slice(3, 15) }

  const { telToTransfer, telCompany } = currentFields

  const portabilityJson = {
    tipo: currentUserConfigs?.esim ? 'A' : 'C',
    id_operadora: telCompany,
    id: userConfigParam.id,
    doadora: telToTransfer.replace(/[^\d]/g, ''),
  }

  async function fetchPortability() {
    try {
      const { retorno, mensagem } = await requests.portability(portabilityJson)
      return { retorno, mensagem }
    } catch (error) {
      console.error("Erro na requisição de busca de operadoras:", error)
    }
  }

  const validateFields = () => {
    const errors = {
      step1: currentFields.telToTransfer === '',
      step2: currentFields.telCompany === undefined,
      invalid: currentFields.telToTransfer.length > 0 && currentFields.telToTransfer.length < 15,
    }
    return errors
  }

  useEffect(() => {
    validateFields()
    // eslint-disable-next-line
  }, [currentFields.telToTransfer, currentFields.telCompany])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const response = await fetchPortability()
      if (response) {
        const { retorno, mensagem } = response
        if (retorno) {
          onClick()
        } else {
          alert(`Falha na solicitação de portabilidade: ${mensagem}`)
        }
      } else {
        alert("Falha na solicitação de portabilidade: resposta indefinida")
      }
    } catch (error) {
      alert(`Falha na solicitação de portabilidade: ${error}`)
    } finally {
      setLoading(false)
    }
  }

  const nextStep = () => {
    const errors = validateFields()
    
    if (currentStep === 0 && !errors.step1 && !errors.invalid) {
      setFormState('step1Error', false)
      setFormState('step1Invalid', false)
      setCurrentStep(currentStep + 1)
    } else {
      setFormState('step1Error', errors.step1)
      setFormState('step1Invalid', errors.invalid)
    }

    if (currentStep === 1 && !errors.step2) {
      setFormState('step2Error', false)
      setCurrentStep(currentStep + 1)
    } else if (currentStep === 1) {
      setFormState('step2Error', errors.step2)
    }

    if (currentStep === 2) {
      setCurrentStep(currentStep + 1)
    }
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const submitStep = currentStep === 3 ? 'submitClass' : ''

  return (
    <Grid className="blackShadow">
      <Grid item className={name ? `${name} portability-modal modal ${submitStep}` : `portability-modal modal ${submitStep}`}>
        <Grid item className="iconArea leftArrow">
          <LeftArrow onClick={currentStep === 0 ? closeModal : prevStep} />
        </Grid>
        <Grid item className="detailArea">
          <Grid className="portabilityIcon">
            <PortabilityIcon />
          </Grid>
          <Grid className="textIcon">
            {text[0]}
          </Grid>
        </Grid>
        <Grid item className="textArea">
          {currentStep === 3 ? (
            <CheckIcon />
          ) : (
            <ChoosePlanText name="esim-modal-text" variables={portabilityModalTextArray} />
          )}
        </Grid>
        
        <Grid container item className="mainSectionModal">
          <StepperPortability variables={portabilityStepperTextArray} currentStep={currentStep} />
        </Grid>

        {currentStep !== 2 && currentStep !== 3 && (
          <Link href={text[15]} target="_blank" className="helpArea">
            <HelpIcon /> {text[16]}
          </Link>
        )}
        <Grid item className="btnArea">
          {
            loading ?
              <CircularProgress />
            :
              <Button onClick={currentStep === 3 ? handleSubmit : nextStep}>
                {currentStep === 3 ? 'Finalizar' : 'Próximo'}
              </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
