import { Grid } from '@mui/material/'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import Section from './Section'
import { GetStaticContent } from '../../api/assets'
import { ISettingsSection, useSettings } from '../../Settings'

type SuperSectionProps = {
  id: string
  className: string
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  options: ISettingsSection,
}

export default function SuperSection({
  id,
  className,
  xs,
  sm,
  md,
  lg,
  xl,
  options: {
    file = undefined,
    top = undefined,
    left = undefined,
    middle = undefined,
    right = undefined,
    bottom = undefined,
  }
}: SuperSectionProps) {
  const [loading, setLoading] = useState(true)
  const [customContent, setContent] = useState<string>()
  const { app } = useSettings()

  useEffect(() => {
    if (app === undefined || file === undefined) {
      setLoading(false)
      return
    }

    const fetchFile = async () => {
      const response = await GetStaticContent({ app, file })
      setContent(response)
      setLoading(false)
    }

    fetchFile()
  }, [app, file])

  if (loading) {
    return <Loading />
  }

  if (customContent) {
    return <div id={id} className={className} dangerouslySetInnerHTML={{ __html: customContent }} />
  }

  return (
    <Grid container xs={xs} sm={sm} md={md} lg={lg} xl={xl} id={id} className={className}>
      {top &&
        <Grid container item id="container-top" className="container-top">
          <Grid container item id="top" className="top">
            <Section id={`${id}-top`} className={`${className}-top`} elements={top} />
          </Grid>
        </Grid>
      }
      {left &&
        <Grid container item id="container-left" className="container-left">
          <Grid container item id="left" className="left">
            <Section id={`${id}-left`} className={`${className}-left`} elements={left} />
          </Grid>
        </Grid>
      }
      {middle &&
        <Grid container item id="container-middle" className="container-middle">
          <Grid container item id="middle" className="middle">
            <Section id={`${id}-middle`} className={`${className}-middle`} elements={middle} />
          </Grid>
        </Grid>
      }
      {right &&
        <Grid container item id="container-right" className="container-right">
          <Grid container item id="right" className="right">
            <Section id={`${id}-right`} className={`${className}-right`} elements={right} />
          </Grid>
        </Grid>
      }
      {bottom &&
        <Grid container item id="container-bottom" className="container-bottom">
          <Grid container item id="bottom" className="bottom">
            <Section id={`${id}-bottom`} className={`${className}-bottom`} elements={bottom} />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}