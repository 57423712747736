import { useEffect, useState } from 'react' 
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useForm } from '../../../../Form'
import { ISettingsItem } from '../../../../Settings'
import requests from '../../../../api/client'
import AutocompleteInput from '../../../AutoCompleteInput'

export default function Step2({ name, variables }: ISettingsItem) {
  const [loading, setLoading] = useState<boolean>(false)
  const { currentFields, formState: { step2Error }, setCurrentFields, setModelledField } = useForm()
  const { text = [] } = variables || {}
  const helperText = step2Error ? 'Campo obrigatório!' : undefined
  const companiesTelArray = currentFields.companiesTel.map(item => ({ id: item.id, label: item.descricao }))
  const value = companiesTelArray ? companiesTelArray.find(({ id }) => id === currentFields.telCompany) : undefined

  useEffect(() => {
    async function fetchCompanies() {
      setLoading(true)
      try {
        const { retorno, data } = await requests.companiesTel()
        if (retorno) {
          setModelledField('companiesTel', data)
          setLoading(false)
        }
      } catch (error) {
        console.error("Erro na requisição de busca de operadoras:", error)
      }
    }
    
    if (currentFields.telCompany === undefined) fetchCompanies()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    setModelledField('companyLabel', value?.label)
    // eslint-disable-next-line
  },[currentFields.telCompany])

  const theme = useTheme()
  const smallInput = useMediaQuery(theme.breakpoints.only('md'))

  return (
    <Grid container item className={name ? `${name} step-2 current-step` : `step-2 current-step`}>
      <Grid item className="circularLabel">2</Grid>
      <Typography variant="body2" className="labelStepArea">{text[0]}</Typography>
      <AutocompleteInput
        required
        id="outlined-tel-company-input"
        label="Selecione"
        name="telCompany"
        value={value}
        onChange={setCurrentFields}
        options={companiesTelArray}
        loading={loading}
        error={step2Error}
        helperText={helperText}
        size={smallInput ? 'small' : 'medium'}
        sx={{ width: '65%' }}
      />
    </Grid>
  )
}