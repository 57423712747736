import { useEffect } from 'react'
import { Grid } from '@mui/material/'
import { SuperSection } from "../structure"
import { usePlan } from '../../Plans'
import { ISettingsPage } from '../../Settings'
import { useLocation, useParams } from 'react-router-dom'
import { useForm } from '../../Form'
import requests from '../../api/client'

export type LayoutProps = {
  page: ISettingsPage
  pageClass: string
}

export default function LayoutDefault({ page, pageClass }: LayoutProps) {
  const { setModelledField } = useForm()
  const vipParam = useParams().id
  const location = useLocation()

  const { planClass } = usePlan()
  const { pageTitle = 'Sua marca', favIcon = '/default-favicon.ico', content: { file, left, right } } = page

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const externalId = searchParams.get('id-externo')

    setModelledField('externalId', externalId)

    if((!vipParam && !localStorage.getItem("VipParam")) || vipParam) localStorage.setItem("VipParam", vipParam || '')
      
    const vipStored = localStorage.getItem("VipParam") || ''
      
    async function fetchVip() {
      try {
        await requests.accessIndication(vipStored)
      } catch (error) {
        console.error('Erro ao enviar acessos de indicação:', error)
      }
    }

    document.title = pageTitle
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
    if (link) {
      link.href = favIcon
    } else {
      const newLink = document.createElement('link')
      newLink.rel = 'icon'
      newLink.href = favIcon
      document.head.appendChild(newLink)
    }

    if (vipStored) fetchVip()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container id="container" className={`layout-contel container ${pageClass} ${planClass}`}>
      <SuperSection id="content" className="content" options={{ file, left, right }} />
    </Grid>
  )
}
