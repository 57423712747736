import { ElementType, useEffect, useRef, useState, FocusEvent } from "react"
import { Grid, Hidden, TextField } from "@mui/material"
import { useInitReq } from "../../../../InitReq"
import requests from "../../../../api/client"
import { ISettingsItem } from "../../../../Settings"
import MaskedTextField, { MaskedTextFieldProps } from "../../../MaskedTextField"
import { useForm } from "../../../../Form"
import { ChoosePlanText } from '../../..'
import AutocompleteInput from "../../../AutoCompleteInput"

export interface StatesListProps {
  estado: string
  uf: string
}

export interface CitiesListProps {
  id: number
  cidade: string
  uf: string
}

interface fieldProps extends Omit<Omit<MaskedTextFieldProps, "mask">, "value"> {
  Component: ElementType
  value: any
  mask?: string | string[]
  options?: { id: string | number | undefined; label: string | undefined }[]
  xs?: number
  md?: number
}

export default function AdressInfo({ name, variables }: ISettingsItem) {
  const { statesList } = useInitReq()
  const { currentFields, formState: { showError }, setCurrentFields, setModelledField, setFormState } = useForm()

  const [citiesList, setCitiesList] = useState<CitiesListProps[]>([])
  const [cidade, setCidade] = useState<string>()
  const fieldsUnfilled = useRef([] as string[])

  const defaultTextArray = [
    'Informações de Endereço',
    'Onde podemos te encontrar',
    'CEP',
    'Estado',
    'Cidade',
    'Rua',
    'Bairro',
    'Número',
    'Complemento',
    'Referência',
  ]
  const { text = defaultTextArray } = variables || {}

  useEffect(() => {
    if (!currentFields.uf) return

    async function fetchCities(uf: string) {
      try {
        const reqCitiesList = await requests.citiesList(uf)
        setCitiesList(reqCitiesList)
      } catch (error) {
        console.error('Erro ao buscar cidades:', error)
      }
    }

    fetchCities(currentFields.uf)
  }, [currentFields.uf])

  const statesListArray = statesList.map(estado => ({ id: estado.uf, label: estado.estado }))
  const citiesListArray = citiesList.map(cidade => ({ id: cidade.id, label: cidade.cidade }))

  useEffect(() => {
    if (citiesList.length > 0 && cidade !== undefined) {
      const cityId = citiesList.find(({ cidade: city }) => city === cidade)

      if (cityId !== undefined) setModelledField('cityId', cityId.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citiesList, cidade])

  async function handleCepBlur(event: FocusEvent<HTMLInputElement>) {
    try {
      const cep = event.target.value
      const { valido, cidade, bairro, rua, uf } = await requests.cepValidator(cep)
      if (valido) {
        setModelledField('uf', uf)
        setCidade(cidade)
        setModelledField('street', rua)
        setModelledField('neighborhood', bairro)
      } else {

      }
    } catch (error) {
      console.error('Erro ao buscar CEP:', error)
    }
  }

  const fields: fieldProps[] = [
    {
      Component: MaskedTextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cep",
      id: "outlined-cep-input",
      value: currentFields.cep,
      fullWidth: true,
      label: text[2],
      type: "tel",
      mask: "#####-###",
      onBlur: handleCepBlur,
    },
    {
      Component: AutocompleteInput,
      required: true,
      helperText: "Campo obrigatório!",
      name: "uf",
      id: "outlined-state-input",
      value: statesListArray.find(({ id }) => id === currentFields.uf),
      options: statesListArray,
      onChange: setCurrentFields,
      label: `${text[3]} *`,
      md: 6,
    },
    {
      Component: AutocompleteInput,
      required: true,
      helperText: "Campo obrigatório!",
      name: "cityId",
      id: "outlined-city-input",
      value: citiesListArray.find(({ id }) => id === currentFields.cityId),
      options: citiesListArray,
      onChange: setCurrentFields,
      label: `${text[4]} *`,
      md: 6,
    },
    {
      Component: TextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "street",
      id: "outlined-street-input",
      value: currentFields.street,
      fullWidth: true,
      label: text[5],
      type: "text",
      md: 6,
    },
    {
      Component: TextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "neighborhood",
      id: "outlined-neighborhood-input",
      value: currentFields.neighborhood,
      fullWidth: true,
      label: text[6],
      type: "text",
      md: 8,
    },
    {
      Component: TextField,
      required: true,
      helperText: "Campo obrigatório!",
      name: "number",
      id: "outlined-number-input",
      value: currentFields.number,
      fullWidth: true,
      label: text[7],
      type: "number",
      xs: 4,
      md: 4,
    },
    {
      Component: TextField,
      required: false,
      helperText: "Campo obrigatório!",
      name: "complement",
      id: "outlined-complement-input",
      value: currentFields.complement,
      fullWidth: true,
      label: text[8],
      type: "text",
      xs: 8,
      md: 6,
    },
    {
      Component: TextField,
      required: false,
      helperText: "Campo obrigatório!",
      name: "reference",
      id: "outlined-reference-input",
      value: currentFields.reference,
      fullWidth: true,
      label: text[9],
      type: "text",
      md: 6,
    },
  ]

  const renderInputs = ({
    Component,
    required,
    helperText,
    name,
    id,
    value,
    fullWidth,
    label,
    type,
    options,
    mask,
    onBlur,
  }: fieldProps, index: number, mob: boolean) => {
    return <Component
      key={`${name}-${index}`}
      required={required}
      error={showError && fieldsUnfilled.current.includes(name) && required}
      helperText={showError && fieldsUnfilled.current.includes(name) && helperText}
      name={name}
      id={id}
      value={value}
      onChange={setCurrentFields}
      fullWidth={fullWidth}
      label={label}
      type={type}
      options={options}
      mask={mask}
      onBlur={onBlur}
      size={mob ? 'medium' : 'small'}
    />
  }

  useEffect(() => {
    fieldsUnfilled.current = fields
      .filter(field => field.required)
      .reduce(
        (agg: string[], { name, value }) => {
          if (["", null, undefined].includes(value)) agg.push(name)
          return agg
        }, []
      )

    setFormState('form3', (fieldsUnfilled.current.length === 0))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    citiesList,
    currentFields.cep,
    currentFields.uf,
    currentFields.cityId,
    currentFields.street,
    currentFields.neighborhood,
    currentFields.number,
  ])

  // MOBILE
  const beforeFracture = fields.filter(field => ['cep', 'uf', 'cityId', 'street', 'neighborhood'].includes(field.name))
  const fracture = fields.filter(field => ['number', 'complement'].includes(field.name))
  const afterFracture = fields.filter(field => ['reference'].includes(field.name))

  // DESKTOP
  const cep = fields.filter(field => ['cep'].includes(field.name))
  const firstFractue = fields.filter(field => ['uf', 'cityId'].includes(field.name))
  const street = fields.filter(field => ['street'].includes(field.name))
  const secondFractue = fields.filter(field => ['neighborhood', 'number'].includes(field.name))
  const thirdFractue = fields.filter(field => ['complement', 'reference'].includes(field.name))

  return (
    <Grid container item className={name ? `${name} adress-info form` : "adress-info form"} xs={12} sx={{ display: 'flex', gap: '20px' }}>
      <Grid item className="textArea">
        <ChoosePlanText name="adress-info-text" variables={{ text }} />
      </Grid>
      <Grid item className="formArea" xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <Hidden mdUp>
          {
            beforeFracture.map((field, index) => renderInputs(field, index, true))
          }
          <Grid item className="fractArea" sx={{ display: 'flex', gap: '20px' }}>
            {
              fracture.map((field, index) => <Grid key={index} item xs={field.xs}>{renderInputs(field, index, true)}</Grid>)
            }
          </Grid>
          {
            afterFracture.map((field, index) => renderInputs(field, index, true))
          }
        </Hidden>
        <Hidden only={['xs', 'xl']}>
          {
            cep.map((field, index) => renderInputs(field, index, false))
          }
          <Grid item className="fractArea">
            {
              firstFractue.map((field, index) => <Grid key={index} item xs={field.md}>{renderInputs(field, index, false)}</Grid>)
            }
          </Grid>
          {
            street.map((field, index) => renderInputs(field, index, false))
          }
          <Grid item className="fractArea">
            {
              secondFractue.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, false)}</Grid>)
            }
          </Grid>
          <Grid item className="fractArea">
            {
              thirdFractue.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, false)}</Grid>)
            }
          </Grid>
        </Hidden>
        <Hidden xlDown>
          {
            cep.map((field, index) => renderInputs(field, index, true))
          }
          <Grid item className="fractArea">
            {
              firstFractue.map((field, index) => <Grid key={index} item xs={field.md}>{renderInputs(field, index, true)}</Grid>)
            }
          </Grid>
          {
            street.map((field, index) => renderInputs(field, index, true))
          }
          <Grid item className="fractArea">
            {
              secondFractue.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, true)}</Grid>)
            }
          </Grid>
          <Grid item className="fractArea">
            {
              thirdFractue.map((field, index) => <Grid key={index} item md={field.md}>{renderInputs(field, index, true)}</Grid>)
            }
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  )
}